<template>
    <div class="row">
        <div class="col-6 mb-3 pe-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <div class="p-2 d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0 text-pri">Balance (USDT)</h6>
                    <div class="text-pri">{{ this.user_balances.balance_eth }}</div>
                </div>
                <WaveTwoComponent></WaveTwoComponent>

            </div>
        </div>
        <div class="col-6 mb-3 ps-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <div class="p-2 d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0 text-pri">Auth amount (USDT)</h6>
                    <div class="text-pri">{{ this.user_balances.auth_amount_eth }}</div>
                </div>
                <WaveOneComponent></WaveOneComponent>

            </div>
        </div>

        <div class="col-6 mb-3 pe-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <div class="p-2 d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0 text-pri">Today Profit (ETH)</h6>
                    <div class="text-pri">{{ this.user_balances.today_eth }}</div>
                </div>
                <WaveThreeComponent></WaveThreeComponent>

            </div>
        </div>
        <div class="col-6 mb-3 ps-1">
            <div class="rounded" style="overflow: hidden;background-color: #25345f;">
                <div class="p-2 d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0 text-pri">Total Profit (ETH)</h6>
                    <div class="text-pri">{{ this.user_balances.total_profit_eth }}</div>
                </div>
                <!-- <h6 class="p-2 mb-0 text-pri">Total Profit (ETH)</h6> -->
                <WaveFourComponent></WaveFourComponent>

            </div>
        </div>
        <!--
        <div class="col-6 mb-3 ps-1">
            <div class="bg-pri rounded p-3">
                <p>{{ $t('today_profit') }} (ETH)</p>
                <Doughnut :data="chartConfig" :options="options" />
            </div>
        </div>

        <div class="col-6 mb-3 pe-1">
            <div class="bg-pri rounded p-3">
                <p>{{ $t('total_profit') }} (TRX)</p>
                <Doughnut :data="chartConfig" :options="options" />
            </div>
        </div>

        <div class="col-6 mb-3 ps-1">
            <div class="bg-pri rounded p-3">
                <p>{{ $t('today_profit') }} (TRX)</p>
                <Doughnut :data="chartConfig" :options="options" />
            </div>
        </div> -->


    </div>
</template>


<script>
import WaveOneComponent from '@/components/WaveOneComponent.vue';
import WaveTwoComponent from '../WaveTwoComponent.vue';
import WaveThreeComponent from '../WaveThreeComponent.vue';
import WaveFourComponent from '../WaveFourComponent.vue';
import userService from '@/services/user.service';


export default {
    components: {
        WaveOneComponent,
        WaveTwoComponent,
        WaveThreeComponent,
        WaveFourComponent
    },
    methods: {
        getUserBalances() {
            userService.getUserBalance().then((Response) => {
                this.user_balances = Response.data.data;
                console.log(this.user_balances);
            })
                .catch(err => {
                    console.log(err)
                });
        }
    },
    mounted() {
        let that = this
        setInterval(function () {
            that.wave1_distance -= 15;
            if (that.wave1_distance < -(window.innerWidth * 2 - 400)) {
                that.wave1_number++;

                that.wave1_distance = 0
            }
        }, 300);
        this.getUserBalances();
    },
    data() {
        return {
            wave1_distance: 0,
            wave1_number: 1,
            user_balances: "",
        }
    },
    watch: {
        '$store.state.auth.user': function () {
            this.getUserBalances()
        }
    }
}
</script>