import { createWebHistory, createRouter } from "vue-router";

import HomeView from '@/views/HomeView';
import WithdrawView from '@/views/WithdrawView';
import ShareView from '@/views/ShareView';
import MyView from '@/views/MyView';
import SupportView from '@/views/SupportView';

const routes = [
    {
        name: 'home',
        path: '',
        component: HomeView,
        meta: {
            
        }
    },
    {
        name: 'withdraw',
        path: '/withdraw',
        component: WithdrawView,
        meta: {
            
        }
    },
    {
        name: 'share',
        path: '/share',
        component: ShareView,
        meta: {
            
        }
    },
    {
        name: 'my',
        path: '/my',
        component: MyView,
        meta: {
            
        }
    },
       {
        name: 'support',
        path: '/support',
        component: SupportView,
        meta: {
            
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router